<template>
  <div class="kyzs_box" ref="kyzs_box">
    <h1>{{ h1 }}</h1>
    <div class="kyzs_nav" v-for="(item,i) in kyzs_list.list" :key="i" @click="pushArticle_xq(item)">
      <section>
        <div class="img"><img :src="baseUrl+item.thumb" alt=""></div>
        <nav>
          <div class="center_text">
            <h1>{{item.title_en}}</h1>
            <span>{{item.description_en}}</span>
          </div>
        </nav>
      </section>
    </div>
    <!-- 底部分页 -->
    <div class="pagination">
      <Pagination :total="from.total" :page="from.page" :limit="from.limit" @pageChange="pageChanges" :en="true"/>
    </div>
  </div>
</template>

<script setup>
import miiter from '@/utils/miitbus.js'
import Pagination from '@/components/Pagination.vue'
import * as axios from '@/api/API.js'
import {ref,reactive,onMounted,defineEmits, watch} from 'vue'
import { useRouter,useRoute } from 'vue-router'
import base64_ from '@/hooks/useBase64text.js'
const router = useRouter()
const route = useRoute()
const baseUrl = process.env.VUE_APP_BASE_URL
// let routeData = JSON.parse(base64_.decode(route.params.obj))
let h1 = ref('') 
const from = reactive({
  page:1,
  limit:10,
  total:0,
  is_en:1,
  type_id:route.params.id
})
const kyzs_box = ref(null)
const kyzs_list = reactive({
  list:[]
})

// 获取导航栏列表的数据
const get_Navaeticle = async () => {
const {data:res} = await axios.get_article_list(from)
if(res.code == 200){
  h1.value = res.data.type_name
  kyzs_list.list = res.data.data
  from.total = res.data.total
}
}
get_Navaeticle()

// 分页
const pageChanges = (ee) => {
  from.page = ee
  get_Navaeticle()
}
from.type_id = route.params.id

const pushArticle_xq = (e) => {
  if(e.jump_url){
    window.open(e.jump_url)
    return
  }
  router.push({
    name:'Enarticlexq',
    params:{
      articleid:e.id,
      id:route.params.id,
      tid:route.params.tid,
      level:route.params.level
    }
  })
}
miiter.on('onEnactilcetap',() => {
    setTimeout(() => {
      from.type_id = route.params.id
      get_Navaeticle()
    })
})

</script> 

<style lang="less" scoped>
@maxw:128rem;
.kyzs_box{
  width: 100%;
  transition: ease .8s;
  cursor: pointer;
>h1{
    font-size: 39/@maxw;
    font-family: Georgia;
    font-weight: bolder;
    text-align: center;
    font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0;
    padding: 0 0 10/@maxw 0;
    cursor: pointer;
  }
  .kyzs_nav{
    margin-top: 40/@maxw;
    width: 100%;
    >section{
      display: flex;
      justify-content: space-between;
      .img{
        width: 230/@maxw;
        // height: 252/@maxw;
        height: 260/@maxw;
        overflow: hidden;
        img{
        width: 100%;
        height: 125%;
        background: #CBC9C9;
        transition: ease .3s;
        vertical-align: middle;
      }
      }
      nav{
        position: relative;
        // width: 874/@maxw;
        width: 1080/@maxw;
        height: 260/@maxw;
        background-image: url("../../../../assets/ldgk1.png");
        background-size: 100% 100%;
        cursor: pointer;
        transition: ease .3s;
        margin-left: 20/@maxw;
        >.center_text{
          margin-left: 50/@maxw;
          // width: 715/@maxw;
          height: 100%;
          color: #174994;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transition: ease .3s;
          h1{
            box-sizing: border-box;
            margin:-15/@maxw 0 15/@maxw 0;padding: 0;
            font-size: 32/@maxw;
            font-family: Georgia;
            font-weight: bolder;
            padding-bottom: 20/@maxw;
            border-bottom: 1px solid #fff;
          }
          span{
            width: 610/@maxw;
            font-size: 21/@maxw;
            font-family: Source Han Sans CN-Medium;
            font-weight: 500;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-top: 60/@maxw;
          }
          p{
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 300;
            font-size: 16/@maxw;
            color: #5F5F5F;
            margin: 0;padding: 0;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            transition: ease .3s !important;
            margin-top: 20/@maxw;
          }
        }
        
      }
    }
  }
  .kyzs_nav:hover{
    >section{
      img{
        transform: scale(1.02);
      }
      nav{
        background-image: url("../../../../assets/ldgk.png");
        >.center_text{
          color: #fff !important;
          p{
            color: #fff !important;
          }
        }
      }
      .time_nav{
        border: 1/@maxw solid #D1BAA6;
        color: #D1BAA6;;
      }
    }
  }
}
.pagination{
  display: flex;
  justify-content: center;
  margin: 6% 0 0 0; 
}
     
@media screen and (max-width:750px) {
    @maxw_:750/100vw;
    .kyzs_box{
      >h1{
        font-size: 35/@maxw_ !important;
        border-bottom:2/@maxw_ solid #174994;
      }
      .kyzs_nav{
        >section{
         .img{
          margin: 0;
          padding: 0;
          width: 230/@maxw_ !important;
          height: 252/@maxw_ !important;
          overflow: hidden;
          img{
          width: 225/@maxw_ !important;
          height: 310/@maxw_ !important;
          background: #CBC9C9;
          transition: ease .3s;
        }
         }
        nav{
          flex: 1;
          height: 252/@maxw_;
          background-image: url("../../../../assets/ejkybj2.png");
          margin-left: 20/@maxw;
        >i{
          position: absolute;
          top: 25/@maxw_;
          left: 15/@maxw_;
          width: 40/@maxw_;
          height: 40/@maxw_;
          background-image: url("../../../../assets/ejky.png");
          background-size: 100% 100%;
        }
        >.center_text{
          margin-left: 60/@maxw_;
          width: 700/@maxw_;
          height: 100%;
          h1{
            margin:-10/@maxw_ 0 15/@maxw_ 0;padding: 0;
            font-size: 32/@maxw_;
            padding-bottom: 15/@maxw_;
          }
          span{
            width: 350/@maxw_;
            font-size: 31/@maxw_;
          }
          p{
            width: 370/@maxw_;
            font-size: 23/@maxw_;
            margin-top: 21/@maxw_;
          }
        }
      }
        }
      }
    }
  }
</style>