<template>
    <div class="pagination_box">
        <span class="left_total" v-if="!props.en">共{{total}}条</span>
        <span class="left_total" v-else>{{total}} in total</span>
        <el-pagination
      v-model:currentPage="currentPage4"
      v-model:page-size="pageSize4"
      :page-sizes="[100, 200, 300, 400]"
      :small="true"
      layout="slot, prev, pager, next,jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :pager-count='3'
      background='#174994'
    ></el-pagination>
    <!-- <div class="to_po"><span>到</span> <input v-model="page_"/> <span>页</span></div>
    <div class="button" @click="Go_">Go</div> -->
    </div>
</template>

<script setup> 
import {ref,onMounted,watch,defineProps,defineEmits, nextTick} from 'vue'
const props = defineProps({
  total: Number,
  page: Number,
  limit: Number,
  en:Boolean
})
const emits = defineEmits(['pageChange'])
let currentPage4 = ref(1)
let total = ref(0)
let page_ = ref(1)
let pageSize4 = ref(10)

let handleSizeChange = (e) => {
}
// 页码
let handleCurrentChange = (e) => {
    page_.value = e
    _pageChange(page_.value)
}

let Go_ = () => {
    handleCurrentChange(parseInt(page_.value))
}
onMounted(()=>{
    // 把分页提示高亮文字改为中文
    nextTick(() => {
    setTimeout(() => {
        if(document.querySelector('.el-pagination__jump')){
          document.querySelector('.el-pagination__jump').childNodes[0].nodeValue = props.en ? 'Go to' : '前往'  
        }
        
    },300)
    })
})

const _pageChange = (page_) => {
    emits("pageChange",page_)
}
// 监听总条数变化
watch(() => props.total,(n,o) => {
    total.value = props.total
    currentPage4.value = props.page
    pageSize4.value = props.limit
},{immediate:true})
</script>

<style lang="less" scoped>
@maxw:128rem;
/deep/.btn-prev,/deep/.btn-next{ 
    border: 1/@maxw solid #3e7ac2 !important;
    border-radius: 50% !important;
    margin: 0 3/@maxw !important;
    color: #3e7ac2;
    cursor: pointer;
    width: 41/@maxw;
    height: 41/@maxw;
    z-index: 99999999999 !important;
}
/deep/.el-pagination--small .el-pager li{
    border: 2px solid #174994;
    width: 41/@maxw;
    height: 41/@maxw;
    font-size: 20/@maxw;
    color: #174994;
    z-index: 99999999999 !important;
}
/deep/.el-pager li{ 
    border: 1/@maxw solid #3e7ac2 !important;
    margin: 0 5/@maxw !important;
    border-radius: 50% !important;
    color: #3e7ac2;
    cursor: pointer;
} 
/deep/.el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
    background-color: #427dc3;
}
/deep/.el-pagination--small span:not([class*=suffix]) {
    font-size: 18/@maxw;
    margin-left: 10/@maxw;
}
/deep/.el-pagination__editor {
    min-width: 0 !important;
}
/deep/.el-pagination__editor,.el-input{
width: 0;
display: flex;
align-items: center;
}
/deep/.el-pagination--small .el-pagination__editor.el-input .el-input__inner {
    width: 28/@maxw;
}
/deep/.el-input--small .el-input__wrapper {
    padding: 1px 7/@maxw;
    width: 50/@maxw;
    height: 38/@maxw;
    border-radius: 50%;
    border: 1px solid #3e7ac2;
    font-size: 18/@maxw;
}
.left_total{
    color: #3e7ac2;
    margin-right: 5/@maxw;
    font-size: 18/@maxw !important;
}
.pagination_box{ 
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #3e7ac2;
    font-size: 10/@maxw;
    .button{
        margin: 0;padding: 0;
        margin-left: 8/@maxw;
        width: 40/@maxw;
        height: 40/@maxw;
        border-radius: 50%;
        background-color: #174d8f;
        text-align: center;
        color: #fff;
        cursor: pointer;
        line-height: 41/@maxw;
        font-size: 20/@maxw;
    }
    .button:hover{
        background-color: #307bd7;
    }
}
.to_po{
    span{
        font-size: 18/@maxw !important;
    }
    input{
        width: 40/@maxw;
        height: 40/@maxw;
        border-radius: 50%;
        border: 1/@maxw solid #3e7ac2 !important;
        text-align: center;
        font-size: 20/@maxw;
        color: #174994;
    }
    
}
@media screen and (max-width:750px) {
    @maxw_:750/100vw;
    /deep/.btn-prev,/deep/.btn-next{ 
    border: 3/@maxw_ solid #3e7ac2 !important;
    margin: 0 3/@maxw_ !important;
    
    width: 52/@maxw_;
    height: 52/@maxw_;
}
    /deep/.el-pagination--small .el-pager li{
    
    width: 52/@maxw_ !important;
    height: 52/@maxw_ !important;
    font-size: 28/@maxw_ !important;
    font-family: Source Han Sans CN-Medium;
    line-height: 52/@maxw_ !important;
}
/deep/.el-pagination--small span:not([class*=suffix]) {
    font-size: 18/@maxw;
    margin-left: 20/@maxw;
}
/deep/.el-pager li{ 
    border: 3/@maxw_ solid #3e7ac2 !important;
    margin: 0 5/@maxw_ !important;
} 
/deep/.el-pagination--small .el-pagination__editor.el-input .el-input__inner {
    width: 38/@maxw_;
}
/deep/.el-input--small .el-input__wrapper {
    padding: 1px 7/@maxw_;
    width: 50/@maxw_;
    height: 48/@maxw_;
    border-radius: 50%;
    border: 1px solid #3e7ac2;
    font-size: 20/@maxw_;
}
.left_total{
    margin-right: 5/@maxw_;
    font-size: 26/@maxw_ !important;
}
.pagination_box{ 
    font-size: 15/@maxw_;
    .button{
        margin-left: 8/@maxw_;
        
        width: 52/@maxw_;
        height: 52/@maxw_;
        line-height: 52/@maxw_;
        font-size: 18/@maxw_;
    }
    .button:hover{
        background-color: #307bd7;
    }
}
.to_po{
    display: flex;
    align-content: center;
    justify-content: space-between;
    span{
        font-size: 18/@maxw_ !important;
       display: flex;
        align-items: center;
        margin: 2px;
    }
    input{
        
        width: 42/@maxw_;
        height: 40/@maxw_;
        border: 3/@maxw_ solid #3e7ac2 !important;
        font-size: 30/@maxw_;
    }
    
}
}
</style>